<template>
  <v-navigation-drawer v-model="drawer" mobile-breakpoint="300">
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="logo-container">
          <img src="@/assets/logo.png" width="120" alt="Orbispay logo" />
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list dense nav tile class="pa-0">
      <v-list-item
        v-for="item in navItems"
        :key="item.title"
        link
        class="sidenav-items"
        :to="item.route"
      >
        <v-icon medium class="mr-2">{{ item.icon }}</v-icon>
        <span>{{ item.title }}</span>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Sidenav',
  data: () => ({
    navItems: [],
    right: null,
    drawer1: true,
  }),
  computed: {
    ...mapGetters(['isSuperAdmin', 'showNavDrawer']),
    drawer: {
      get() {
        return !(this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm)
      },
      set(value) {
        return value
      },
    },
  },
  methods: {
    gotoLink(item) {
      this.$router.push(item.route)
    },
  },
  mounted() {
    if (this.isSuperAdmin === true || this.isSuperAdmin === 'true') {
      this.navItems.push({
        title: 'Users',
        icon: 'mdi-account-multiple-outline',
        route: '/home',
      })
      this.navItems.push({
        title: 'Operators',
        icon: 'mdi-view-dashboard',
        route: '/operators',
      })
    } else {
      this.navItems.push({
        title: 'Users',
        icon: 'mdi-account-multiple-outline',
        route: '/home',
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.sidenav-items {
  span {
    font-size: 13px;
    color: var(--subtitle);
  }
}

.logo-container {
  text-align: center;

  img {
    width: 150px;
    margin-bottom: 20px;
    margin-top: 15px;
  }
}

.v-navigation-drawer {
  display: none;

  @media screen and (min-width: 768px) {
    display: flex;
  }
}

.v-list--nav .v-list-item {
  padding: 0 20%;
  border-radius: 0;
}

.v-list .v-list-item--active {
  color: var(--primary);
  background: #fff;
  border-right: 3px solid var(--primary);
}

.help-info-card {
  h4 {
    color: #000;
  }
  p {
    margin-bottom: 0;
    font-size: 13px;
  }
}

.orbispay-footer {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  align-items: center;

  span {
    font-size: 13px;
    color: var(--subtitle);
  }
}
</style>